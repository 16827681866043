import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";

export const SidebarContainer = styled.div`
  display: none;
  @media screen and (max-width: 870px) {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 0vh;
    max-height: 200px;
    background: ${(props) => props.theme.headerBg};
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0.5rem 5vw 0.5rem 5vw;
    top: 80px;
    right: 0%;
    transition: 0.3s ease;
    opacity: 100%;
    height: ${({ isOpen }) => (isOpen ? "200px" : "0%")};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transition: all 0.5s ease;
    overflow: hidden;
  }
`;

export const NavLogo = styled(ScrollLink)`
  justify-self: flex-start;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "League Gothic", sans-serif;
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme }) => theme.logoColor};
  white-space: nowrap;
  & p {
    font-size: 2.6rem;
    line-height: 1.2rem;
    font-family: "League Gothic", sans-serif;
    font-weight: 600;
  }

  @media screen and (max-width: 480px) {
    & p {
      font-size: 2rem;
      line-height: 1rem;
    }
    & span {
      font-size: 0.8rem;
    }
  }
`;
export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
`;

export const NavLinks = styled(ScrollLink)`
  color: ${(props) => props.theme.other};
  font-family: "League Gothic", sans-serif;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  padding: 0 1rem;
  cursor: pointer;
  transition: all 0.05s ease-in;
  font-weight: 700;

  letter-spacing: 1px;
  position: relative;
  transition: 0.3s;
`;
