import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
export const FooterContainer = styled.footer`
  min-height: 80px;
  height: 100%;
  max-height: 100%;
  width: 100vw;
  background-color: ${(props) => props.theme.headerBg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10vh;
  @media screen and (max-width: 1024px) {
    padding: 1rem 6vw 0 6vw;
  }

  @media screen and (max-width: 768px) {
    padding: 0.5rem 5vw;
  }
`;

export const NavLogo = styled(ScrollLink)`
  color: ${(props) => props.theme.logoColor};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  & p {
    font-size: 2.6rem;
    line-height: 1.2rem;
    font-family: "League Gothic", sans-serif;
    font-weight: 700;
    letter-spacing: 0.2rem;
  }

  @media screen and (max-width: 480px) {
    & p {
      font-size: 2rem;
      line-height: 1rem;
      letter-spacing: 0rem;
    }
    & span {
      font-size: 0.8rem;
    }
  }
`;

export const HelpText = styled.p`
  font-size: 0.9rem;
  font-family: "League Gothic", sans-serif;
  font-weight: 700;

  color: ${(props) => props.theme.headerText};
`;

export const IconsContainer = styled.div`
  flex: 0.3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    justify-content: center;
    width: 100%;
  }
`;
export const IconLink = styled.a``;
export const Image = styled.img`
  width: 30px;
  @media screen and (max-width: 768px) {
    margin-right: 1vw;
  }
`;
