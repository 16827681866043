import styled from "styled-components";

export const ScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  @media screen and (max-width:768px) {
    margin-top: 2vh;
  }
`;

export const Donations = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DonateBtn = styled.a`
  background-color: ${(props) => props.theme.primary};
  text-decoration: none;
  padding: 0.25rem 1.25rem;
  border-radius: 6px;
  width: 60%;
text-align: center;
  font-weight: 500;
  color: ${(props) => props.theme.tertiary};
`;

export const DonateContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  flex: 0.275;
  border: 1px solid ${(props) => props.theme.primary};
  padding: 1rem;
  border-radius: 6px;
  @media screen and (max-width: 768px) {
    margin-bottom: 2vh;
  }
`;

export const Image = styled.img`
  height: auto;
  width: 55%;
`;
export const DonationTitle = styled.label`
  color: ${(props) => props.theme.primary};
  font-family: "Unbounded", sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
`;
