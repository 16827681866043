import React from "react";
import HeroSection from "components/layout/HomeScreen/HeroSection";
import NPPSection from "components/layout/HomeScreen/NPPSection";
import EshopSection from "components/layout/HomeScreen/Eshop";
import CrowdFundingSection from "components/layout/HomeScreen/CrowdFunding";
import Donation from "components/layout/HomeScreen/Donate";
import Video from "components/layout/HomeScreen/VideoSection";
const Home = () => {
  return (
    <>
      <HeroSection />
      <NPPSection />
      <EshopSection />
      <CrowdFundingSection />
      <Donation />
      <Video />
    </>
  );
};

export default Home;
