import React, { useState } from "react";
import { ScreenContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { npp1, npp2 } from "helpers/assets";
import { StepperTransition } from "helpers/transitions";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
const BACKGROUNDS = [npp1, npp2];

const NPPSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <ScreenContainer id="npp" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="NPP">
          {activeIndex === 0 && (
            <SectionContent
              key="NPP-First"
              title="D2 Team CORP 501(c)(3)"
              description="Join our Hightech Humanity (H2)® in the United States today. The process is very easy. Simply click the button below to setup your onboarding session with the D2 Team CORP 501(c)(3)."
              cta="D2 Team Corp"
              href="https://go.d2.team"
              {...StepperTransition}
            />
          )}

          {activeIndex === 1 && (
            <SectionContent
              key="NPP-Second"
              title="303 Overwatch A.S.B.L"
              description="Join our Hightech Humanity (H2)® in Europe, the Middle East or Africa today. The process is very easy. Simply click the button below to setup your onboarding session with 303 Overwatch A.S.B.L."
              cta="303 Overwatch"
              href="https://go.303ow.team"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>
      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        from="NPP"
      />
    </ScreenContainer>
  );
};

export default NPPSection;
