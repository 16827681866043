import React from "react";
import { FooterContainer, NavLogo, IconLink, IconsContainer, Image} from "./Elements";
import {
  Facebook,
  Gmail,
  Linkedin,
  Youtube,
  Instagram,
  Github,
  Tiktok,
} from "helpers/assets";
const Footer = () => {
  const SOCIAL_ICONS = [
    {
      icon: Tiktok,
      href: "https://www.tiktok.com/@303.overwatch.asbl",
      name: "Tiktok-EU",
    },
      {
      icon: Tiktok,
      href: "https://www.tiktok.com/@d2.team.corp",
      name: "Tiktok-US",
    },
    {
      icon: Linkedin,
      href: "https://www.linkedin.com/company/303overwatch/",
      name: "Linkedin-EU",
    },
    {
      icon: Linkedin,
      href: "https://www.linkedin.com/company/d2teamcorp/",
      name: "Linkedin-US",
    },
    {
      icon: Youtube,
      href: "https://www.youtube.com/channel/UCmWJZ1AbHbpFeIbsDHIQKAQ",
      name: "Youtube-EU",
    },
     {
      icon: Youtube,
      href: "https://www.youtube.com/@d2teamcorp",
      name: "Youtube-US",
    },
    {
      icon: Facebook,
      href: "https://www.facebook.com/303Overwatch/",
      name: "Facebook-EU",
    },
     {
      icon: Facebook,
      href: "https://www.facebook.com/Meta.Humanity.Project",
      name: "Facebook-US",
    },
    {
      icon: Instagram,
      href: "https://www.instagram.com/303.overwatch.asbl",
      name: "Instagram-EU",
    },
     {
      icon: Instagram,
      href: "https://www.instagram.com/d2.team.corp",
      name: "Instagram-US",
    },
  ];
  return (
    <FooterContainer className="container">
      <NavLogo to="home" smooth={true}>
        <p>3M3</p>
      </NavLogo>
      <IconsContainer>
        {SOCIAL_ICONS?.map((icon, index) => {
          return (
            <IconLink
              key={`${icon.name}-${index}`}
              href={icon.href}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              <Image src={icon.icon} />
            </IconLink>
          );
        })}
      </IconsContainer>
    </FooterContainer>
  );
};

export default Footer;
