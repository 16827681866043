import styled from "styled-components";
import { motion } from "framer-motion";
export const SliderContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  flex: 0.45;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    position: relative;
  }
`;

export const BG = styled(motion.div)`
  height: 100%;
  width: 100%;
  flex: 0.8;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  background-image: ${(props) => `url(${props.$bg})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 6px;
  opacity: 1;
  position: absolute;
  list-style: none;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 80%;
  /* transition: all 0.8s ease !important; */
  transition: left 0.8s ease;

  @media screen and (max-width: 768px) {
    position: absolute;
    list-style: none;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 100%;
    border-radius: 0px;

    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.35) 0%,
        rgba(4, 11, 33, 0.5) 100%
      ),
      ${(props) => `url(${props.$bg})`};
  }
`;

export const BgVideo = styled(motion.video)`
  height: 100%;
  width: 100%;
  flex: 0.8;
  transition: all 0.2s ease;
  border-radius: 6px;

  @media screen and (max-width: 768px) {
    position: absolute;
    list-style: none;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`;

export const ArrowContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.85;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @media screen and (max-width: 768px) {
    margin-top: 5vh;
  }
`;

export const Arrow = styled.div`
  height: max-content;
  width: max-content;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.other};
  cursor: pointer;
  transform: scale(0.8);
  transition: all 0.2s ease-in;
  &:hover {
    transform: scale(1);
    border: 1px solid ${(props) => props.theme.secondary};

    svg {
      fill: ${(props) => props.theme.secondary};
    }
  }

  /* @media screen and (max-width: 768px) {
    display: none;
  } */
`;
