import { theme } from "./theme";
import { ThemeProvider } from "styled-components";
import Header from "components/layout/Header";
import Home from "screens/Home";
import Footer from "components/layout/Footer";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Home />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
