import React from "react";
import { SidebarContainer, LinkContainer, NavLinks } from "./elements";
const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      {/* <NavLogo to="home" smooth>
        <p>3M3</p>
      </NavLogo> */}
      <LinkContainer>
        <NavLinks to="home" onClick={toggle} smooth>
          3M3
        </NavLinks>
        <NavLinks to="npp" onClick={toggle} smooth offset={0}>
          Non-Profit Partners
        </NavLinks>
        <NavLinks to="eshop" onClick={toggle} smooth offset={-80}>
          eShops
        </NavLinks>

        <NavLinks to="crowdfunding" onClick={toggle} smooth offset={-80}>
          Crowdfunding
        </NavLinks>
      </LinkContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
