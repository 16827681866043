import styled from "styled-components";
import { motion } from "framer-motion";
export const ScreenContainer = styled.div`
  min-height: calc(100vh - 80px);
  padding-top: 100px;
  height: calc(100vh - 80px);
  max-height: 100%;
  max-width: 100%;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  margin-top: 10vh;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 100%;
    padding-bottom: 1rem;
  }
`;

export const ContentContainer = styled(motion.div)`
  height: 80%;
  width: 100%;
  flex: 0.5;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex: 1;
  }
`;
