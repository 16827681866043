import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

export const ContentContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 2vh;
  z-index: 2;
`;

export const SectionTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.primary};
  font-family: "Unbounded", sans-serif;

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.25rem;
  }
`;

export const SectionDescription = styled.p`
  color: ${(props) => props.theme.secondary};
  font-size: 0.8rem;
  line-height: 1.25rem;
  font-weight: 600;
  font-family: "Unbounded", sans-serif;
  letter-spacing: 1px;
  text-align: start;
  color: ${(props) => props.theme.secondary};

  @media screen and (max-width: 768px) {
    font-size: 1.45rem;
    line-height: 2rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.75rem;
    line-height: 1.3rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 0.65rem;
    line-height: 1.1rem;
    margin-top: 2vh;
  }
`;

export const SectionCta = styled.a`
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 1rem 1rem;
  font-family: "Unbounded", sans-serif;
  cursor: pointer;
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.tertiary};
  text-decoration: none;

  @media screen and (max-width: 480px) {
    padding: 1rem 0.5rem;
    margin-top: 2vh;
    font-size: 0.75rem;
  }
`;

export const SectionLink = styled(Link)`
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 1rem 1rem;
  font-family: "Unbounded", sans-serif;
  cursor: pointer;
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.tertiary};
  text-decoration: none;

  @media screen and (max-width: 480px) {
    padding: 1rem 0.5rem;
    margin-top: 2vh;
    font-size: 0.75rem;
  }
`;

export const StepListContainer = styled.ol`
  height: max-content;
  width: max-content;
  text-align: start;
`;

export const StepList = styled.li`
  color: ${(props) => props.theme.secondary};
  line-height: 1.5rem;
  font-weight: 600;
  list-style: none;
  font-family: "Unbounded", sans-serif;
  letter-spacing: 1px;
  color: ${(props) => props.theme.secondary};

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;
