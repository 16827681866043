import React, { useState } from "react";
import { HeroContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { hero2, hero3, hero4, hero5, hero6, hero1New } from "helpers/assets";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
import { StepperTransition } from "helpers/transitions";
const BACKGROUNDS = [hero1New, hero2, hero3, hero4, hero5, hero6];

const STEPS = [
  "Hightech Humanity (H2)®",
  "Digital Skills Mentoring",
  "Digital Skills Training",
  "Micro Credentials",
  "Interview Preperation",
  "Resume/CV Preperation",
];

const HeroSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <HeroContainer id="home" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="HERO">
          {activeIndex === 0 && (
            <SectionContent
              key="First-Hero"
              title="3 Million Mind Movement (3M3) ™"
              cta="Crowdfunding"
              list={STEPS}
              to="crowdfunding"
              {...StepperTransition}
            />
          )}

          {activeIndex === 1 && (
            <SectionContent
              key="Second-Hero"
              title="3 Million Mind Movement (3M3) ™"
              description="We are crowdfunding contributions to help democratize technology."
              cta="Crowdfunding"
              to="crowdfunding"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>

      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        from="Hero"
      />
    </HeroContainer>
  );
};

export default HeroSection;
