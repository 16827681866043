import React, { useState, useEffect } from "react";
import {
  NavbarContainer,
  Nav,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
} from "./elements";
import { Twirl as Hamburger } from "hamburger-react";
import { theme } from "theme";
const Navbar = ({ isOpen, toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 30) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <NavbarContainer $scrollNav={scrollNav}>
      <Nav>
        <NavLogo to="home" smooth $scrollNav={scrollNav}>
          <p>3M3</p>
        </NavLogo>
        <MobileIcon>
          <Hamburger
            toggled={isOpen}
            toggle={toggle}
            size={20}
            duration={0.35}
            easing="ease-in"
            rounded
            $scrollNav={scrollNav}
            color={!scrollNav ? theme.other : theme.headerText}
          />
        </MobileIcon>
        <NavMenu>
          <NavItem>
            <NavLinks
              to="home"
              smooth
              activeClass="active"
              spy={true}
              offset={-500}
              $scrollNav={scrollNav}
              $color={!scrollNav ? theme.other : theme.tertiary}
            >
              3M3
            </NavLinks>
          </NavItem>

          <NavItem>
            <NavLinks
              to="npp"
              smooth
              spy={true}
              offset={-80}
              activeClass="active"
              $scrollNav={scrollNav}
              $color={!scrollNav ? theme.other : theme.tertiary}
            >
              Non-Profit Partners
            </NavLinks>
          </NavItem>

          <NavItem>
            <NavLinks
              to="eshop"
              smooth
              activeClass="active"
              spy={true}
              offset={-80}
              $scrollNav={scrollNav}
              $color={!scrollNav ? theme.other : theme.tertiary}
            >
              eShops
            </NavLinks>
          </NavItem>

          <NavItem>
            <NavLinks
              to="crowdfunding"
              smooth
              activeClass="active"
              spy={true}
              offset={-80}
              $scrollNav={scrollNav}
              $color={!scrollNav ? theme.other : theme.tertiary}
            >
              Crowdfunding
            </NavLinks>
          </NavItem>
        </NavMenu>
      </Nav>
    </NavbarContainer>
  );
};

export default Navbar;
