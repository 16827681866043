import React from "react";
import styled from "styled-components";
import { cf3 } from "helpers/assets";

const ScreenContainer = styled.div`
  padding-top: 100px;
  height: 100%;
  max-width: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export const StyledVideo = styled.video`
  height: 100%;
  width: 50%;
  flex: 0.8;
  transition: all 0.2s ease;
  border-radius: 6px;

  @media screen and (max-width:768px) {
    width: 100%;
  }
`;
const Video = () => {
  return (
    <ScreenContainer className="container">
      <StyledVideo autoPlay={false} loop muted controls>
        <source src={cf3} type="video/mp4" />
      </StyledVideo>
    </ScreenContainer>
  );
};

export default Video;
