import React, { useState } from "react";
import { ScreenContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { eshop1, eshop2 } from "helpers/assets";
import { StepperTransition } from "helpers/transitions";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
const BACKGROUNDS = [eshop1, eshop2];

const EshopSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <ScreenContainer id="eshop" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="ESHOP">
          {activeIndex === 0 && (
            <SectionContent
              title="United States eShop"
              key="ESHOP-FIRST"
              description="Help us spread the word and to empower people with free digital training. Purchase some merchandise from our non-profit partner eShop. All of the merchandise has a QR-Code to help start the conversation about Hightech Humanity (H2)®." 
              cta="United States eShop"
              href="https://na.hightechhumanity.com/"
              {...StepperTransition}
            />
          )}

          {activeIndex === 1 && (
            <SectionContent
              key="ESHOP-Second"
              title="European Union eShop"
              description="Help us spread the word and to help empower people with free digital training. Purchase some merchandise from our non-profit partner eShop. All of the merchandise has a QR-Code to help start the conversation about Hightech Humanity (H2)®."
              cta="European Union Shop"
              href="https://eu.hightechhumanity.com/"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>
      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </ScreenContainer>
  );
};

export default EshopSection;
