import React, { useCallback } from "react";
import {
  SliderContainer,
  Arrow,
  BG,
  ArrowContainer,
  BgVideo,
} from "./Elements";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { AnimateSharedLayout } from "framer-motion";
import { theme } from "theme";

const BackgroundSlider = ({
  backgrounds = [],
  activeIndex,
  setActiveIndex,
  from,
  autoPlay = true,
}) => {
  const moveToNext = useCallback(() => {
    if (activeIndex === backgrounds.length - 1) {
      setActiveIndex(0);
      return;
    }
    setActiveIndex(activeIndex + 1);
  }, [activeIndex, backgrounds, setActiveIndex]);

  const moveToPrev = useCallback(() => {
    if (activeIndex === 0) {
      setActiveIndex(backgrounds.length - 1);
      return;
    }
    setActiveIndex(activeIndex - 1);
  }, [activeIndex, backgrounds, setActiveIndex]);

  return (
    <SliderContainer>
      <AnimateSharedLayout key={from}>
        {backgrounds.map((bg, index) => {
          return (
            <>
              {!bg[index].includes("mp4") ? (
                <BG
                  layout
                  key={`${from}-slider-${index}-${Math.random()}`}
                  // layoutId={'bg'}
                  $bg={bg}
                  initial={{
                    scale: 0,
                    left: `${(index - activeIndex) * 100}%`,
                  }}
                  animate={{
                    left: `${(index - activeIndex) * 100}%`,
                    scale: index === activeIndex ? 1 : 0.8,
                    filter: index === activeIndex ? "" : "blur(5px)",
                    opacity: index === activeIndex ? 1 : 0.25,
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 180,
                    damping: 30,
                  }}
                />
              ) : (
                <BgVideo autoPlay loop >
                  <source src={backgrounds[activeIndex]} />
                </BgVideo>
              )}
            </>
          );
        })}
      </AnimateSharedLayout>
      <ArrowContainer>
        <Arrow className="left" onClick={moveToPrev}>
          <BsArrowLeftShort size={25} color={theme.other} />
        </Arrow>
        <Arrow className="right" onClick={moveToNext}>
          <BsArrowRightShort size={25} color={theme.other} />
        </Arrow>
      </ArrowContainer>

      {/* {backgrounds?.map((bg, i) => {
        const animation = i === 0 ? "fade-out" : getAnimation(i);
        const classes = activeIndex === i ? "active zoom-in" : animation;
        // if (!bg.includes("mp4")) {
          return (
            <BG
              key={`BG${i}`}
              $bg={bg}
              className={`${classes}`}
              ref={addToRefs}
              $index={i}
            />
          );
        // } 
        // else
        //   return (
        //     <>

        //     </>
        //   );
      })} */}
    </SliderContainer>
  );
};

export default BackgroundSlider;
