import React from "react";
import {
  ScreenContainer,
  DonateContainer,
  Image,
  DonationTitle,
  DonateBtn,
  Donations,
} from "./Elements";
import { paypal, venmo } from "helpers/assets";
const Donation = () => {
  return (
    <ScreenContainer className="container">
      <Donations>
        <DonateContainer>
          <Image src={paypal} alt="donation" />
          <DonationTitle>For European Union</DonationTitle>
          <DonateBtn
            href="https://www.paypal.com/donate/?hosted_button_id=YVLU56GFVGD74"
            target="_blank"
            referrerPolicy="no-referrer"
          >
            Donate
          </DonateBtn>
        </DonateContainer>
        <DonateContainer>
          <Image src={paypal} alt="donation" />
          <DonationTitle>For United States</DonationTitle>
          <DonateBtn
            href="https://www.paypal.com/us/fundraiser/charity/4270671"
            target="_blank"
            referrerPolicy="no-referrer"
          >
            Donate
          </DonateBtn>
        </DonateContainer>
        <DonateContainer>
          <Image src={venmo} alt="donation" />
          <DonationTitle>For United States</DonationTitle>
          <DonateBtn
            href="https://account.venmo.com/u/d2teamcorp"
            target="_blank"
            referrerPolicy="no-referrer"
          >
            Donate
          </DonateBtn>
        </DonateContainer>
      </Donations>
    </ScreenContainer>
  );
};

export default Donation;
