import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";

export const NavbarContainer = styled.nav`
  height: ${({ $scrollNav }) => (!$scrollNav ? "100px" : "80px")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 10;
  padding: 1rem 15vw 1rem 15vw;
  background-color: ${({ $scrollNav, theme }) => theme.headerBg};
  transition: 0.8s all ease-out;
  @media screen and (max-width: 1024px) {
    padding: 1rem 6vw 0 6vw;
    transition: 0.8s all ease;
    transition: 0.2s height ease;
  }

  @media screen and (max-width: 768px) {
    padding: 0.5rem 5vw;
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;
  @media screen and (max-width: 380px) {
    padding: 0 10px;
  }
`;

export const NavLogo = styled(ScrollLink)`
  justify-self: flex-start;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "League Gothic", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-decoration: none;
  color: ${({ $scrollNav, theme }) =>
    $scrollNav ? theme.logoColor : theme.other};
  white-space: nowrap;
  & p {
    font-size: 2.6rem;
    line-height: 1.2rem;
    font-family: "League Gothic", sans-serif;
  }

  @media screen and (max-width: 480px) {
    & p {
      font-size: 2rem;
      line-height: 1rem;
      letter-spacing: 0rem;
    }
    & span {
      font-size: 0.8rem;
    }
  }
`;

export const Logo = styled.img`
  max-width: 10vh;
  height: auto;
  margin-right: 10px;
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 900px) {
    transition: 0.2s translate ease;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3c3c3c;
    top: 15%;
    right: 0;
    font-size: 1.8rem;
    cursor: pointer;
  }

  @media screen and (max-width: 380px) {
    padding: 0 10px;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;
export const NavItem = styled.li`
  /* height: 80px; */
  font-family: "Pacifico", cursive;
`;

export const NavLinks = styled(ScrollLink).attrs(() => ({
  activeClass: "active",
}))`
  color: ${(props) => props.theme.headerText};
  display: flex;
  white-space: nowrap;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease-in;
  height: 100%;
  cursor: pointer;
  width: 100%;
  font-family: "League Gothic", sans-serif;
  font-weight: 600;

  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: -50%;
    z-index: -1;
    opacity: 0;
    border-radius: 10px;
    background-color: ${({ $scrollNav, theme }) =>
      $scrollNav ? theme.other : theme.logoColor};
    transition: 0.3s;
  }
  &:hover {
    &::after {
      width: 20%;
      opacity: 1;
    }
  }
  &.active {
    color: ${({ $scrollNav, theme }) =>
      $scrollNav ? theme.logoColor : theme.other};
    &::after {
      width: 60%;
      opacity: 1;
    }
  }
`;
