export const getAnimation = (i) => {
  return i % 2 === 0 ? "slide-right" : "slide-left";
};

export const StepperTransition = {
  initial: { opacity: 0, scale: "75%", x: "-50%" },
  animate: { scale: "100%", opacity: 1, x: "0%" },
  transition: { type: "spring", stiffness: 180, damping: 30 },
  exit: {
    scale: "75%",
    opacity: 0,
    x: "-50%",
    transition: { type: "spring", stiffness: 180, damping: 30 },
  },
};

export const ImageTransition = {
  initial: { opacity: 0, x: "50%" },
  animate: { opacity: 1, x: "0%" },
  transition: { type: "spring", stiffness: 180, damping: 30 },
  exit: {
    opacity: 0,
    x: "50%",

    transition: {
      type: "spring",
      stiffness: 180,
      damping: 30,
    },
  },
};
