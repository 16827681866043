import React, { useState } from "react";
import { ScreenContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { cf1, cf2 } from "helpers/assets";
import { StepperTransition } from "helpers/transitions";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
const BACKGROUNDS = [cf1, cf2];

const CrowdFundingSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <ScreenContainer id="crowdfunding" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="Crowdfunding">
          {activeIndex === 0 && (
            <SectionContent
              title="D2 Team CORP 501(c)(3)"
              description="Support D2 Team CORP."
              cta="D2 Team Corp"
              key="Crowdfunding-Second"
              href="https://www.paypal.com/us/fundraiser/charity/4270671"
              {...StepperTransition}
            />
          )}

          {activeIndex === 1 && (
            <SectionContent
              title="303 Overwatch A.S.B.L"
              description="Support 303 Overwatch"
              cta="303 Overwatch"
              key="Crowdfunding-Third"
              href="https://www.google.com"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>
      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        from="crowdfunding"
      />
    </ScreenContainer>
  );
};

export default CrowdFundingSection;
